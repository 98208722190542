import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { CheckIcon } from 'icons/internal/CheckIcon';
import { HelpIcon } from 'icons/internal/HelpIcon';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Password, NotifiedPassword, StyledPassword } from '../index';
import { password as variants, sizes } from './helpers/variants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Textbox" title="Password" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-password"
    }}>{`Basic password`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <Password\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <Password onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "visible-password"
    }}>{`Visible password`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <Password\n            initialType=\"text\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <Password initialType="text" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-password"
    }}>{`Labeled password`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <Password\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <Password label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-password"
    }}>{`Transitional labeled password`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"password\">\n          <Password\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="password" mdxType="FormComponentDemo">
            <Password transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "disabled-password"
    }}>{`Disabled password`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <Password\n            disabled\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <Password disabled label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "custom-icons-password"
    }}>{`Custom icons password`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <Password\n            iconHide={CheckIcon}\n            iconHideSize={12}\n            iconShow={HelpIcon}\n            iconShowSize={12}\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <Password iconHide={CheckIcon} iconHideSize={12} iconShow={HelpIcon} iconShowSize={12} onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-password"
    }}>{`Contrast password`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper contrast>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          contrast\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <Password\n            contrast\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo contrast unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <Password contrast transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Password" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "styled-password"
    }}>{`Styled password`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"password\"\n        >\n          <StyledPassword\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            passwordIconColor={\'#9D69D5\'}\n            passwordIconHoveredColor={\'#642da0\'}\n            passwordInputContainerBackgroundColor={\'transparent\'}\n            passwordInputContainerBorderColor={\'#9D69D5\'}\n            passwordInputContainerFocusedBorderColor={\'#57278C\'}\n            passwordInputContainerHoveredBorderColor={\'#642da0\'}\n            passwordInputTextColor={\'#642da0\'}\n            passwordLabelTextColor={\'#B995E1\'}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="password" mdxType="FormComponentDemo">
            <StyledPassword transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} passwordIconColor={'#9D69D5'} passwordIconHoveredColor={'#642da0'} passwordInputContainerBackgroundColor={'transparent'} passwordInputContainerBorderColor={'#9D69D5'} passwordInputContainerFocusedBorderColor={'#57278C'} passwordInputContainerHoveredBorderColor={'#642da0'} passwordInputTextColor={'#642da0'} passwordLabelTextColor={'#B995E1'} variant={variant} mdxType="StyledPassword" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n    render={level => (\n      <Looper\n        list={variants}\n        render={variant => (\n          <Rhythm key={`${variant}-${level}`} py={3}>\n            <FormComponentDemo unwrapped property=\"value\" type=\"password\">\n              <NotifiedPassword\n                clearable\n                transitional\n                label=\"Super fantastic label\"\n                level={level}\n                notification={`This is an example ${level} notification.`}\n                onChange={(event, value) => console.log(value)}\n                onClear={() => console.log(\'cleared\')}\n                variant={variant}\n              />\n            </FormComponentDemo>\n          </Rhythm>\n        )}\n      />\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <Looper list={variants} render={variant => <Rhythm key={`${variant}-${level}`} py={3} mdxType="Rhythm">
              <FormComponentDemo unwrapped property="value" type="password" mdxType="FormComponentDemo">
                <NotifiedPassword clearable transitional label="Super fantastic label" level={level} notification={`This is an example ${level} notification.`} onChange={(event, value) => console.log(value)} onClear={() => console.log('cleared')} variant={variant} mdxType="NotifiedPassword" />
              </FormComponentDemo>
            </Rhythm>} mdxType="Looper" />} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Looper\n    list={sizes}\n    render={size => (\n      <Rhythm grouped my={3}>\n        <Looper\n          list={variants}\n          render={variant => (\n            <Rhythm key={variant} py={3}>\n              <FormComponentDemo unwrapped property=\"value\" type=\"password\">\n                <Password\n                  transitional\n                  label={`Size ${size}`}\n                  onChange={(event, value) => console.log(value)}\n                  size={size}\n                  variant={variant}\n                />\n              </FormComponentDemo>\n            </Rhythm>\n          )}\n        />\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HelpIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Password,
      NotifiedPassword,
      StyledPassword,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={sizes} render={size => <Rhythm grouped my={3} mdxType="Rhythm">
          <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
                <FormComponentDemo unwrapped property="value" type="password" mdxType="FormComponentDemo">
                  <Password transitional label={`Size ${size}`} onChange={(event, value) => console.log(value)} size={size} variant={variant} mdxType="Password" />
                </FormComponentDemo>
              </Rhythm>} mdxType="Looper" />
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "password"
    }}>{`Password`}</h3>
    <Props of={Password} mdxType="Props" />
    <h3 {...{
      "id": "styledpassword"
    }}>{`StyledPassword`}</h3>
    <Props of={StyledPassword} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      